import React from "react"
import Scroll from "react-scroll"

const Element = Scroll.Element
const scroller = Scroll.scroller

const scrollDown = section => {
  scroller.scrollTo(section, {
    duration: 1500,
    delay: 0,
    smooth: true,
    offset: -50,
  })
}

export default scrollDown
