import { Link, graphql, useStaticQuery } from 'gatsby'
import React from 'react'
import PropTypes from 'prop-types'

import styled from 'styled-components'


import devices from '../devices'

const HamburgerButton = styled.a`
    display: none;

    width: 40px;
    height: 30px;
    position: relative;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: .5s ease-in-out;
    -moz-transition: .5s ease-in-out;
    -o-transition: .5s ease-in-out;
    transition: .5s ease-in-out;
    cursor: pointer;

    z-index: 101;

    ${devices.tablet`
        display: inline-block;
    `}

    span {
        display: block;
        position: absolute;
        height: 4px;
        width: 100%;
        background: black;
        border-radius: 4px;
        opacity: 1;
        left: 0;
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
        -webkit-transition: .25s ease-in-out;
        -moz-transition: .25s ease-in-out;
        -o-transition: .25s ease-in-out;
        transition: .25s ease-in-out;
    }

    span:nth-child(1) {
        top: 0px;
    }

    span:nth-child(2),#nav-icon3 span:nth-child(3) {
        top: 10px;
    }

    span:nth-child(4) {
        top: 20px;
    }

    &.open span:nth-child(1) {
        top: 10px;
        width: 0%;
        left: 50%;
    }

    &.open span:nth-child(2) {
        transform-origin: 65% 35%;
        -webkit-transform: rotate(45deg);
        -moz-transform: rotate(45deg);
        -o-transform: rotate(45deg);
        transform: rotate(45deg);
    }

    &.open span:nth-child(3) {
        transform-origin: 65% 35%;
        -webkit-transform: rotate(-45deg);
        -moz-transform: rotate(-45deg);
        -o-transform: rotate(-45deg);
        transform: rotate(-45deg);
    }

    &.open span:nth-child(4) {
        top: 10px;
        width: 0%;
        left: 50%;
    }

    &.open span {
        background: black;
    }
`

class Hamburger extends React.Component {

    constructor(props) {
        super(props)
        this.handleClick = this.handleClick.bind(this)
    }

    handleClick() {
        this.props.openMenu()
    }

    render() {
        return(
            <>
                <HamburgerButton onClick={this.handleClick} className={this.props.menuState ? 'open' : 'closed'}>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                </HamburgerButton>
            </>
        )
    }

}

export default Hamburger
