import React from "react"
import { graphql, Link, useStaticQuery } from "gatsby"
import PropTypes from "prop-types"
import Img from "gatsby-image"
import styled from "styled-components"
import Scroll from "react-scroll"

import {
  FullWidthImageWrapper,
  SectionWrapper,
  ColumnWrapper,
  Column,
  ColumnText,
  ColumnHeading,
  CitationWrapper,
  CitationText,
  CitationHeading,
  PageHeadingWrapper,
  PageHeading,
  FadedShapeContainerBottom,
  FadedShapeBottom,
  FadedShapeContainerTop,
  FadedShapeTop,
  ProgressBarContainer,
  ProgressBar,
  ScrollDownContainer,
  ScrollDownText,
  ScrollDownIcon,
} from "../pagecomponents/page-layout"

import devices from "../devices"
import scrollDown from "../scroller"

const TallerFadedShapeContainer = styled(FadedShapeContainerBottom)`
  height: 60rem;
`

const WhatColumnWrapper = styled(ColumnWrapper)`
  ${devices.laptop`
        flex-direction: column;
    `}
`

const WhatSectionWrapper = styled(SectionWrapper)`
  margin-bottom: 12rem;
`

const ImageWrapper = styled.div`
  margin-bottom: 40px;
`

const ColumnImg = styled(Img)`
  z-index: 2;
  max-width: 600px;
  width: 60%;
  margin: 0 auto;
`

const WhatCitationText = styled(CitationText)``

const VideoWrapper = styled.div`
  position: relative;
  overflow: hidden;
  width: 100%;
  padding-top: 56.25%;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
  }

  ${devices.laptop`
    margin-top: 20px;
  `}
`

const What = () => {
  const Element = Scroll.Element

  const data = useStaticQuery(graphql`
    query {
      glc: file(relativePath: { eq: "glc-full-width-larger-height.png" }) {
        childImageSharp {
          fluid(maxWidth: 4096, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      circle: file(relativePath: { eq: "GL-wholeness.png" }) {
        childImageSharp {
          fluid(maxWidth: 4096, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      scrolldown: file(relativePath: { eq: "scrolldown.png" }) {
        childImageSharp {
          fluid(maxWidth: 4096, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  const scrollDownInner = () => {}

  return (
    <>
      <ProgressBarContainer>
        <ProgressBar />
      </ProgressBarContainer>
      <SectionWrapper>
        <PageHeadingWrapper>
          <PageHeading>WHAT & HOW</PageHeading>
        </PageHeadingWrapper>
        <CitationWrapper>
          <WhatCitationText>
            Through our current exploration, we believe that the Guardian of
            Life is a universal essence that serves as a foundation for any
            partnerships and collaborations to collectively bring the necessary
            social transformation in transcending into a new brave world. This
            essence is guided by a living, evolving, and co-created Guardian of
            Life Fundamentals in order to seed the culture we all want to
            co-create.
          </WhatCitationText>
        </CitationWrapper>
        <ScrollDownContainer onClick={() => scrollDown("second-section")}>
          <ScrollDownText>READ MORE</ScrollDownText>
          <ScrollDownIcon fluid={data.scrolldown.childImageSharp.fluid} />
        </ScrollDownContainer>
      </SectionWrapper>
      <FullWidthImageWrapper>
        <Img fluid={data.glc.childImageSharp.fluid} />
      </FullWidthImageWrapper>
      <Element name="second-section"></Element>
      <WhatSectionWrapper>
        <WhatColumnWrapper>
          <Column>
            <ColumnText>
              Guardian of Life collaborates through collective action of its
              networks, loosely interdependent and independent, united in
              diversity through Guardian of Life essence. It is not a process as
              most humans have understood it to be in this present time. It's an
              embodied / immersive / experiential living system process where
              the process is not in the system but in the being of the person
              (also a living system / nature).
            </ColumnText>

            <ColumnText>
              <strong>Being and living the living system.</strong>
            </ColumnText>
            <ColumnText>
              <strong>
                Being and living the culture of the living system.
              </strong>
            </ColumnText>
            <ColumnText>
              For this collaboration to be deeply meaningful, we explore
              together how to move our hearts, minds and hands for collective
              action. This is supported by the practical realisation of our
              collaborations through experimentation and prototyping of
              independent and interdependent initiatives, based on timeless and
              modern knowledge, and time tested tools.
            </ColumnText>
            <ColumnText>
              This culture allows for designing systems, processes and
              governance that is true and of integrity to living systems:
              co-creating conditions from all living forms to thrive and live as
              an organism rather than individual entities.
            </ColumnText>
            <ColumnText>
              From this essence we can then experiment to act locally, connect
              and collaborate regionally and learn globally (through online and
              offline platforms), finding a multitude of unique and regional
              ways on how live in emergence and as a living organism.
            </ColumnText>
            <ColumnHeading>LIVING MISSION</ColumnHeading>
            <ColumnText>
              Through deep listening and nature-connection, we co-create a
              regenerative culture of care, learning and celebration that
              embodies interdependency and values meaningful connection.
            </ColumnText>
          </Column>
          <Column>
            <VideoWrapper>
              <iframe
                src="https://player.vimeo.com/video/384380688"
                frameborder="0"
                allow="autoplay; fullscreen"
                allowfullscreen
              ></iframe>
            </VideoWrapper>
            <ColumnHeading>
              Pioneering Gathering, Stockholm archipelago, September 25-28 2019.
            </ColumnHeading>
            <ColumnText>
              In September 2019 a diverse group of 34 wonderful people from many
              corners of the earth came together in an exploration of: What and
              how is it to be a Guardian of Life.
            </ColumnText>
            <ColumnText>
              Respected Indigenous Elders from 4 continents, pioneers of heart
              (activists/entrepreneurs/funders) came together for the immersive
              experience of love and care for all life forms, to be present to
              emergence and co-creation, in the service of the collective good,
              that enabled deep connection, collaboration and continuous
              experimentation as one united family. Some participants were not
              originally invited and didn't know what the gathering was about.
              They asked to be invited, willing to drop everything and do
              whatever it takes - clients, a talk to 200 people, spend their own
              money, travel for 24 hours etc - to be at the gathering. Most
              people involved were in some ways guided to the team and team
              guided to them by a larger force, out of our human control and
              decision-making.
            </ColumnText>
          </Column>
          <TallerFadedShapeContainer>
            <FadedShapeBottom />
          </TallerFadedShapeContainer>
        </WhatColumnWrapper>
      </WhatSectionWrapper>
      <ImageWrapper>
        <ColumnImg fluid={data.circle.childImageSharp.fluid} />
      </ImageWrapper>
    </>
  )
}

export default What
