import { Link, graphql, StaticQuery } from 'gatsby'
import React from 'react'
import PropTypes from 'prop-types'
import Img from 'gatsby-image'

import styled from 'styled-components'

import 'typeface-open-sans'

import devices from './devices'
import Hamburger from './pagecomponents/hamburger-menu'

const HeaderWrapper = styled.div`
    background-color: white;
    width: 100%;
    display: flex;
    align-items: center;
    padding-left: 0rem;
    padding-top: 0.4rem;
    height: 75px;
    position: fixed;
    top: 0;

    transition: top 0.2s ease-in-out;
    box-shadow: 0 0 3px 0 rgba(0,0,0,0.22);

    z-index: 100;
    justify-content: space-around;

    ${devices.tablet`
        padding: 0 2rem;
        justify-content: space-between;
    `}
`

const LogoWrapper = styled.div`
    width: 12rem;
    background-color: none;

    ${devices.tablet`

    `}
`

const MenuWrapper = styled.div`
    background-color: none;

`

const Menu = styled.ul`
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: space-between;
    width: 20rem;
    flex-flow: row;
    position: relatve;
    transition: right 0.5s;

    ${devices.tablet`
        flex-flow: column;
        position: absolute;
        top: 0;
        margin-left: 0;
        padding-top: 25vh;
        padding-bottom: 25vh;
        padding-left: 0rem;
        background-color: white;
        box-shadow: 0 0 3px 0 rgba(0,0,0,0.5);
        height: 100vh;
        right: -25rem;
    `}

    &.open {
        right: 0;

    }

    &.closed {
        right: -25rem;
    }
`

const MenuItem = styled.li`
    display: inline-block;
    font-family: 'Open Sans', sans-serif;
    font-weight: 200;
    font-size: 0.9rem;

    a{
        color: black;
        text-decoration: none;
    }

    ${devices.tablet`
        display: block;
        line-height: 5;
        font-weight: 700;
        font-size: 1rem;
        padding-left: 6rem;

        a {
            color: black;
        }
    `}
`

const MenuContact = styled.p`
    display: none;
    font-family: 'Open Sans', sans-serif;
    font-weight: 200;
    font-size: 0.9rem;
    text-align: center;
    position: absolute;
    bottom: 2rem;
    width: 100%;

    ${devices.tablet`
        display: block;
    `}
`

class Header extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            showMenu: false,
        }
        this.showMenu = this.showMenu.bind(this)
        this.closeMenu = this.closeMenu.bind(this)
        this.menuRef = React.createRef()
    }

    showMenu() {
        this.setState(state => ({
            showMenu: !state.showMenu,
        }))
    }

    closeMenu() {
        this.setState(state => ({
            showMenu: false,
        }))

        this.menuRef.current.blur()
    }

    render() {
        return (
            <>
                <HeaderWrapper>
                    <LogoWrapper>
                        <Link to="/">
                            <StaticQuery query={graphql`
                            query {
                                file(relativePath: { eq: "logo-black.png" }) {
                                    childImageSharp {
                                        fluid(maxWidth: 500, quality: 90) {
                                            ...GatsbyImageSharpFluid_withWebp
                                        }
                                    }
                                }
                            }
                        `
                            } render={data => (
                                <Img fluid={data.file.childImageSharp.fluid} />
                            )
                            }/>
                        </Link>
                    </LogoWrapper>
                    <MenuWrapper>
                        <Menu className={this.state.showMenu ? 'open' : 'closed'}>
                            <MenuItem>
                                <Link to="/">HOME</Link>
                            </MenuItem>
                            <MenuItem>
                                <Link to="/why">WHY</Link>
                            </MenuItem>
                            <MenuItem>
                                <Link to="/what-we-do">WHAT & HOW</Link>
                            </MenuItem>
                            <MenuItem>
                                <Link to="/who-we-are">WHO</Link>
                            </MenuItem>
                            <MenuContact>
                                Contact us &mdash; <a href="mailto:hello@guardianoflife.org">hello@guardianoflife.org</a>
                            </MenuContact>
                        </Menu>
                    </MenuWrapper>
                    <Hamburger menuState={this.state.showMenu} openMenu={this.showMenu}/>
                </HeaderWrapper>
            </>
        )
    }

}

export default Header
