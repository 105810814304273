import styled, { keyframes } from "styled-components"
import Img from "gatsby-image"

import "typeface-open-sans"
import "typeface-merriweather"

import devices from "../devices"

export const FullWidthImageWrapper = styled.div`
  width: 100%;
  height: auto;
  border: 1px solid transparent;

  .gatsby-image-wrapper {
    ::after {
      box-shadow: inset 0px 11px 8px -10px rgba(0, 0, 0, 0.5),
        inset 0px -11px 8px -10px rgba(0, 0, 0, 0.5);
      content: "";
      display: block;
      height: 100%;
      position: absolute;
      top: 0;
      width: 100%;
    }
  }

  ${devices.laptop`
        .gatsby-image-wrapper{
            height: 50vh;
        }

    `}
`

export const SectionWrapper = styled.div`
  flex-direction: ${props => (props.column ? "column" : "row")};
  flex-wrap: wrap;
  display: flex;
  width: 100vw;
  margin-top: 16rem;
  margin-bottom: 16rem;

  ${devices.laptop`
        flex-direction: column;
        margin-top: 10rem;
        margin-bottom: 10rem;
        align-items: center;
    `}

  ${devices.tablet`
        margin-top: 4rem;
        margin-bottom: 4rem;
    `}
`

export const ColumnWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  margin-top: -8rem;
  margin-bottom: -8rem;

  ${devices.laptop`
        flex-direction: column;
        margin-top: -6rem;
        margin-bottom: -6rem;
        width: 90%;
        align-items: center;
    `}

  ${devices.tablet`
        margin-top: 0rem;
        margin-bottom: 0rem;
    `}
`

export const Column = styled.div`
  width: 40%;

  ${devices.laptop`
        width: 100%;
    `}
`

export const ColumnText = styled.p`
  font-family: "Open Sans", sans-serif;
  font-weight: 200;
  font-size: 1.2rem;

  ${devices.tablet`
        font-size: 1rem;
    `}
`

export const ColumnHeading = styled.h2`
  font-family: "Merriweather", serif;
  font-weight: 700;
  font-size: 1.8rem;
  margin-top: 2rem;
`

export const CitationWrapper = styled.div`
    ${devices.desktop`
        width: 70%;
    `}

    ${devices.laptop`
        margin-top: 1rem;
        width: 70%;
    `}

    ${devices.tablet`
        width: 90%;
    `}

`

export const CitationText = styled.p`
    font-family: 'Open Sans', sans-serif;
    font-weight: 200;
    font-size: 1.2rem;
    width: 40rem;

    ${devices.laptop`
        margin-top: 1rem;
        width: 100%;
    `}

    ${devices.laptop`
        margin-top: 1rem;
        width: 100%;
    `}

    ${devices.tablet`
        margin-top: 0;
        font-size: 1rem;
    `}

    opacity: 1;
	animation-name: fadeInOpacity;
	animation-iteration-count: 1;
	animation-timing-function: ease-in;
	animation-duration: 1s;

    @keyframes fadeInOpacity {

        0% {
    		opacity: 0;
    	}
    	100% {
    		opacity: 1;
    	}
    }
`

export const CitationHeading = styled.h2`
  font-family: "Merriweather", serif;
  font-weight: 700;
  font-size: 2rem;
`

export const PageHeadingWrapper = styled.div`
  width: 30%;

  ${devices.desktop`
        width: 30%;
        margin-top: -1.8rem;
    `}

  ${devices.laptop`
        width: 100%;
        margin-top: 0;
    `}
`

export const PageHeading = styled.h1`
  font-family: "Merriweather", serif;
  font-weight: 700;
  font-size: 3.6rem;
  text-align: right;
  margin-right: 2rem;

  opacity: 1;
  animation-name: fadeInOpacity;
  animation-iteration-count: 1;
  animation-timing-function: ease-in;
  animation-duration: 1s;

  @keyframes fadeInOpacity {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  ::after {
    content: " \\2014";
    font-family: "Open Sans", sans-serif;
    font-weight: 100;

    opacity: 1;
    animation-name: fadeInOpacity;
    animation-iteration-count: 1;
    animation-timing-function: ease-in;
    animation-duration: 0.75s;

    @keyframes fadeInOpacity {
      0% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }
  }

  ${devices.laptop`
        margin-right: 0;
        text-align: center;
        ::after{
            content: "";
        }
    `}
`

export const FadedShapeContainerBottom = styled.div`
  overflow: hidden;
  position: absolute;
  display: inline-flex;
  right: 0;
  width: 30rem;
  top: 110vh;
  height: 60rem;
  z-index: -1;

  ${devices.laptop`
        display: none;
    `}
`

export const FadedShapeBottom = styled.div`
  background-color: rgba(247, 215, 148, 0.25);
  border-radius: 50%;
  position: absolute;
  width: 60rem;
  height: 60rem;
  z-index: -1;
`

export const FadedShapeContainerTop = styled.div`
  position: absolute;
  display: inline-flex;
  width: 40rem;
  height: 40rem;
  z-index: -1;
  transform: translate(100%, -75%);
`

export const FadedShapeTop = styled.div`
  background-color: rgba(255, 107, 107, 0.15);
  border-radius: 50%;
  width: 100%;
  height: 100%;
  z-index: -1;
`

export const ProgressBarContainer = styled.div`
  width: 100%;
  height: 4px;
  display: flex;
`

export const ProgressBar = styled.div`
  display: inline-block;
  background-color: #fdcb6e;
  z-index: 2;

  max-width: 100%;
  height: 100%;

  animation-name: widthIncrease;
  animation-iteration-count: 1;
  animation-timing-function: grow1;
  animation-duration: 1.25s;

  @keyframes widthIncrease {
    from {
      width: 0;
    }
    to {
      width: 100%;
    }
  }
`

const Pulsate = keyframes`
    0%, 100% {
        color: black;
    }
    50% {
        color: rgba(0,0,0, 0.25);
    }
`

export const ScrollDownContainer = styled.div`
  margin: 0 auto;
  margin-top: 4rem;
  margin-bottom: -4rem;
  cursor: pointer;

  opacity: 1;
  animation-name: fadeInOpacity;
  animation-iteration-count: 1;
  animation-timing-function: ease-in;
  animation-duration: 1s;

  @keyframes fadeInOpacity {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  ${devices.tablet`
    margin-top: 2rem;
    margin-bottom: -2rem;
  `}
`

export const ScrollDownText = styled.p`
  font-family: "Open Sans", sans-serif;
  font-weight: 100;
  font-size: 12px;
  margin-bottom: 0;

  animation: ${Pulsate} 2s infinite;
  animation-delay: 10s;
`

export const ScrollDownIcon = styled(Img)``
