import { Link, graphql, useStaticQuery } from "gatsby"
import React from "react"
import PropTypes from "prop-types"

import styled from "styled-components"

import "typeface-open-sans"

import devices from "./devices"

const FooterWrapper = styled.div`
  height: 6rem;
  background-color: #3c6382;
  display: flex;
  align-items: center;
  justify-content: center;

  ${devices.tablet`
        height: 4rem;
    `}
`

const FooterText = styled.p`
  font-family: "Open Sans", sans-serif;
  font-weight: 200;
  font-size: 1.2rem;
  color: white;
  margin-bottom: 0;

  a {
    color: white;
  }

  ${devices.tablet`
        font-size: 1rem;
    `}

  ${devices.mobileM`
        text-align: center;
        font-size: 0.9rem;
    `}

  ${devices.mobileS`
        text-align: center;
        font-size: 0.8rem;
    `}
`

const Footer = () => {
  return (
    <>
      <FooterWrapper>
        <FooterText>
          Get involved &mdash; email us at:{" "}
          <a href="mailto:hello@guardianoflife.org">hello@guardianoflife.org</a>
        </FooterText>
      </FooterWrapper>
    </>
  )
}

export default Footer
