import React from 'react'
import { graphql, Link } from 'gatsby'
import PropTypes from 'prop-types'

import Header from './newheader'
import Footer from './footer'
import SEO from './seo'
import Img from 'gatsby-image'
import styled from 'styled-components'
import { createGlobalStyle } from 'styled-components'

//import '../style/page.sass'
//import 'bootstrap/dist/css/bootstrap.min.css'
import 'typeface-open-sans'
import 'typeface-merriweather'

import WhyPage from './davospages/why'
import WhatPage from './davospages/what'
import WhoPage from './davospages/who'

const GlobalStyle = createGlobalStyle`
    body{
        padding-top: 75px;
    }
`

const PageContainer = styled.div`

`

const PageContent = styled.div`

`

export default function Page({
    data, // this prop will be injected by the GraphQL query below.
}) {
    const { markdownRemark } = data // data.markdownRemark holds our post data
    const { frontmatter, html } = markdownRemark

    return (
        <>
            <GlobalStyle />
            <SEO
                title={frontmatter.title}
                description={frontmatter.description}
            />
            <div className="page-container">
                <Header nav={true} />
                <div className="page-content">
                    {
                        frontmatter.path == "/why"
                            ? (
                                <WhyPage />
                            )
                            : null
                    }
                    {
                        frontmatter.path == "/what-we-do"
                            ? (
                                <WhatPage />
                            )
                            : null
                    }
                    {
                        frontmatter.path == "/who-we-are"
                            ? (
                                <WhoPage />
                            )
                            : null
                    }

                </div>
                <Footer />
            </div>

        </>
    )
}

export const pageQuery = graphql`
    query($path: String!) {
        markdownRemark(frontmatter: { path: { eq: $path } }) {
            html
            frontmatter {
                path
                title
                description
            }
        }
        glc: file(relativePath: { eq: "glc-2.jpg" }) {
            childImageSharp {
                # Specify the image processing specifications right in the query.
                # Makes it trivial to update as your page's design changes.
                fluid(maxWidth: 4096, quality: 90) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        partnerlogo1: file(relativePath: { eq: "partner-ekskaret.png" }) {
            childImageSharp {
                # Specify the image processing specifications right in the query.
                # Makes it trivial to update as your page's design changes.
                fluid(maxWidth: 500, quality: 90) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        partnerlogo2: file(relativePath: { eq: "partner-wisdomweavers.png" }) {
            childImageSharp {
                # Specify the image processing specifications right in the query.
                # Makes it trivial to update as your page's design changes.
                fluid(maxWidth: 500, quality: 90) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        partnerlogo3: file(relativePath: { eq: "partner-greencross.jpg" }) {
            childImageSharp {
                # Specify the image processing specifications right in the query.
                # Makes it trivial to update as your page's design changes.
                fluid(maxWidth: 500, quality: 90) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        circleDiagram: file(relativePath: { eq: "GL-wholeness.png" }) {
            childImageSharp {
                # Specify the image processing specifications right in the query.
                # Makes it trivial to update as your page's design changes.
                fluid(maxWidth: 1200, quality: 90) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
    }
`
