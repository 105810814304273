import React from "react"
import { graphql, Link, useStaticQuery } from "gatsby"
import PropTypes from "prop-types"
import Img from "gatsby-image"
import styled from "styled-components"
import ReadMoreAndLess from "react-read-more-less"

import "typeface-merriweather"
import "typeface-open-sans"

import {
  FullWidthImageWrapper,
  SectionWrapper,
  ColumnWrapper,
  Column,
  ColumnText,
  ColumnHeading,
  CitationWrapper,
  CitationText,
  CitationHeading,
  PageHeadingWrapper,
  PageHeading,
  FadedShapeContainerBottom,
  FadedShapeBottom,
  FadedShapeContainerTop,
  FadedShapeTop,
  ProgressBarContainer,
  ProgressBar,
  ScrollDownContainer,
  ScrollDownText,
  ScrollDownIcon,
} from "../pagecomponents/page-layout"

import devices from "../devices"
import Button from "../button"
import Scroll from "react-scroll"
import scrollDown from "../scroller"

const BioContainer = styled.div``

const Bio = styled.div`
  display: flex;
  width: 60rem;
  flex-direction: row;
  margin-bottom: 2rem;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);

  :hover {
    box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  }

  ${devices.laptop`
        width: 40rem;
    `}

  ${devices.tablet`
        width: 100%;

    `}
`

const BioName = styled.h3`
  font-family: "Merriweather", serif;
  font-weight: 700;
  font-size: 1.4rem;
`

const BioInfo = styled(ColumnText)`
  ${devices.tabletS`
        font-size: 0.8rem;

    `}
`

const BioNameInfoWrapper = styled.div`
  width: 70%;
  padding-left: 2rem;
  padding-right: 2rem;
  padding-top: 5rem;

  ${devices.laptop`
        padding-top: 1.4rem;
    `}
`

const BioImgContainer = styled.div`
  width: 30%;
`

const BioImg = styled(Img)``
const BioSection = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-top: 0rem;
  align-items: center;
  align-content: center;
  width: 100%;
  margin-bottom: 4rem;
`

const BioSectionHeading = styled(ColumnHeading)`
  font-size: 3rem;
  text-align: center;
  margin-bottom: 2rem;

  ${devices.tablet`
        font-size: 2.6rem;
    `}

  ${devices.mobileM`
        font-size: 2rem;
    `}
`

const BioSectionWrapper = styled.div`
  width: 100%;
  margin-top: 6rem;

  ${devices.laptop`
        margin-top: 2rem;
    `}

  ${devices.tablet`
        margin-top: 0;
    `}
`

const WhoSectionWrapper = styled(SectionWrapper)`
  margin-top: 0;
  margin-bottom: 0;
  flex-direction: column;

  ${devices.laptop`
        margin-top: 4rem;
        margin-bottom: 4rem;
    `}

  ${devices.tablet`
        margin-top: 4rem;
        margin-bottom: 4rem;
    `}
`

const EmailButton = styled.a`
  text-align: center;
  font-size: 1.2rem;
  line-height: 4;
  display: block;
  width: 20rem;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);

  :hover {
    box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
    text-decoration: none;
  }
`

const EmailButtonWrapper = styled.div`
  margin-top: 4rem;
`

const PartneringOrgsSection = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

const PartneringOrg = styled.div`
  width: 10rem;

  :first-child {
    margin-right: 4rem;
  }

  display: flex;
  flex-direction: column;
  align-items: center;

  ${devices.tablet`
        width: 6rem;
    `}
`

const PartneringOrgImg = styled(Img)`
  width: 100%;
`

const PartneringOrgDescription = styled.a`
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
  font-size: 1rem;
  text-decoration: none;
  color: black;
  text-align: center;

  ${devices.mobileM`
        font-size: 0.9rem;
    `}
`

const Who = () => {
  const Element = Scroll.Element

  const data = useStaticQuery(graphql`
    query {
      glc: file(relativePath: { eq: "glc-full-width-larger-height.png" }) {
        childImageSharp {
          fluid(maxWidth: 4096, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      nils: file(relativePath: { eq: "netlify/image6-square.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 4096, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      mila: file(relativePath: { eq: "netlify/image5-square.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 4096, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      marco: file(relativePath: { eq: "netlify/marco-steiger.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 4096, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      goran: file(relativePath: { eq: "netlify/image2.png" }) {
        childImageSharp {
          fluid(maxWidth: 4096, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      alexander: file(relativePath: { eq: "netlify/alexander-crawford.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 4096, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      caroline: file(relativePath: { eq: "netlify/image4.png" }) {
        childImageSharp {
          fluid(maxWidth: 4096, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      tonia: file(relativePath: { eq: "netlify/tonia-moya.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 4096, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      tommy: file(
        relativePath: { eq: "netlify/tommy-gardh-download-by-mila.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 4096, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      isak: file(relativePath: { eq: "netlify/isak-bw.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 4096, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      leo: file(
        relativePath: { eq: "netlify/leo-yankton-download-by-mila.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 4096, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      merle: file(relativePath: { eq: "netlify/image3.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 4096, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      maria: file(relativePath: { eq: "netlify/img_0492.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 4096, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      jan: file(relativePath: { eq: "netlify/jan-friman.png" }) {
        childImageSharp {
          fluid(maxWidth: 4096, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      jenny: file(relativePath: { eq: "netlify/jenny-andersson.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 4096, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      joe: file(relativePath: { eq: "netlify/joe-ross.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 4096, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      talya: file(relativePath: { eq: "netlify/talya-weinberg.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 4096, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      robert: file(relativePath: { eq: "netlify/robert-krook.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 4096, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      ilarion: file(relativePath: { eq: "netlify/Ilarion+Merculieff.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 4096, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      wisdomweavers: file(relativePath: { eq: "wisdomweavers.png" }) {
        childImageSharp {
          fluid(maxWidth: 4096, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      ekskaret: file(relativePath: { eq: "ekskaret.jpeg" }) {
        childImageSharp {
          fluid(maxWidth: 4096, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      scrolldown: file(relativePath: { eq: "scrolldown.png" }) {
        childImageSharp {
          fluid(maxWidth: 4096, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  return (
    <>
      <ProgressBarContainer>
        <ProgressBar />
      </ProgressBarContainer>
      <SectionWrapper>
        <PageHeadingWrapper>
          <PageHeading>WHO</PageHeading>
        </PageHeadingWrapper>
        <CitationWrapper>
          <CitationText>
            Guided by synchronicities and a deep calling of our heart and soul,
            we came together and formed a loose collaboration of co-creators,
            enablers, and wisdom keepers with a shared vision and core values.
            We aim to realise and evolve together through the various shades of
            darkness and light striving towards a more beautiful, balanced, and
            harmonious world for present and future generations. Embracing
            wholeness and emergence in our journeys of inquiry, exploration and
            discovery.
          </CitationText>
        </CitationWrapper>
        <ScrollDownContainer onClick={() => scrollDown("second-section")}>
          <ScrollDownText>READ MORE</ScrollDownText>
          <ScrollDownIcon fluid={data.scrolldown.childImageSharp.fluid} />
        </ScrollDownContainer>
      </SectionWrapper>
      <FullWidthImageWrapper>
        <Img fluid={data.glc.childImageSharp.fluid} />
      </FullWidthImageWrapper>
      <Element name="second-section"></Element>
      <WhoSectionWrapper>
        <BioSectionHeading>PARTNERING ORGANISATIONS</BioSectionHeading>
        <PartneringOrgsSection>
          <PartneringOrg>
            <PartneringOrgImg
              fluid={data.wisdomweavers.childImageSharp.fluid}
            />
            <PartneringOrgDescription href="https://www.wisdomweavers.world/">
              Wisdom Weavers of the World
            </PartneringOrgDescription>
          </PartneringOrg>
          <PartneringOrg>
            <PartneringOrgImg fluid={data.ekskaret.childImageSharp.fluid} />
            <PartneringOrgDescription href="http://ekskaret.se/">
              Ekskäret Foundation
            </PartneringOrgDescription>
          </PartneringOrg>
        </PartneringOrgsSection>
        <BioSectionWrapper>
          <BioSectionHeading>CO-CREATORS</BioSectionHeading>
          <BioSection>
            <Bio>
              <BioImgContainer>
                <BioImg fluid={data.mila.childImageSharp.fluid} />
              </BioImgContainer>
              <BioNameInfoWrapper>
                <BioName>Mila Aliana</BioName>
                <BioInfo>
                  <ReadMoreAndLess
                    charLimit={150}
                    readMoreText=" Read more"
                    readLessText=" Read less"
                  >
                    For years, Mila has been navigating the quest to learn how
                    living systems and their lifecycle, within nature and
                    society, behave and balance themselves in times of
                    disruptive and emerging change. She is exploring projects of
                    heart experimenting to this essence and upholding the
                    integrity to think, be, and act to co-create conditions for
                    all life forms to flourish. Mila is presently piloting the
                    Guardian of Life with other like-minded heart warriors and
                    pioneers, experimenting a way of life with integrity to
                    living systems and its lifecycle. The intention is to instil
                    an embodied way of being that cares for all life forms so
                    that life flourishes for many generations to come. This way
                    of being is shaped by timeless and modern wisdom - Elders,
                    communities, and existing social innovation projects that
                    are already fully committed, immersed, and integrated in
                    this way of life. She is honoured to be a designated
                    emissary of the Wisdom Weavers of the World , a wisdom
                    circle of Elders from around the world with a call to
                    humanity and united message: to think, be, and act from the
                    heart in order to respond to our imbalanced and urgent
                    times, and work together towards an everlasting harmony and
                    thriving of life. Mila is also a global senior consultant
                    with an entrepreneurial background for close to 30 years.
                    She has lead multi-million dollar portfolios of strategic
                    change projects and programmes within diverse industries,
                    sectors and countries. Mila has experiences in world
                    economic forums, international project developments, social
                    innovation projects, and social, economic, and environmental
                    justice activism and campaigning.
                  </ReadMoreAndLess>
                </BioInfo>
              </BioNameInfoWrapper>
            </Bio>
            <Bio>
              <BioNameInfoWrapper>
                <BioName>Nils Joneborg</BioName>
                <BioInfo>
                  <ReadMoreAndLess
                    charLimit={150}
                    readMoreText=" Read more"
                    readLessText=" Read less"
                  >
                    Nils is a psychiatrist seeing and sensing the world from
                    from deep understanding of human suffering originating from
                    three core dimensions of health: physical,
                    psychological/social and spiritual. From this systems lens
                    view, he sees the need for wholeness in order for profound
                    and collective healing to take place. For many years, Nils
                    has dedicated his professional life to the discovery and
                    application of different strands of integrative medicine and
                    how to create opportunity for organisational health.
                    Prototyping and learning in different sectors. He sees the
                    disconnection from inner and outer nature to be the greatest
                    cause of disease in social, economic and environmental
                    systems. Nils is convinced that a healthy and connected
                    community is essential for healing, including the healing of
                    collective trauma. A wholeness understanding of health and
                    well-being is necessary for harmony and integrity when
                    embracing the role of the Guardian of Life.
                  </ReadMoreAndLess>
                </BioInfo>
              </BioNameInfoWrapper>
              <BioImgContainer>
                <BioImg fluid={data.nils.childImageSharp.fluid} />
              </BioImgContainer>
            </Bio>

            <Bio>
              <BioImgContainer>
                <BioImg fluid={data.marco.childImageSharp.fluid} />
              </BioImgContainer>
              <BioNameInfoWrapper>
                <BioName>Marco Steiger</BioName>
                <BioInfo>
                  <ReadMoreAndLess
                    charLimit={150}
                    readMoreText=" Read more"
                    readLessText=" Read less"
                  >
                    Marco was born in 1966 in Switzerland into a family of
                    musicians, is married with two children. In 2004, Marco
                    co-founded the World Spirit Forum in Arosa, dedicating his
                    life work for transformation into action. He also co-founded
                    a private School at the Lake of Constance as a first
                    shifting educational experience and started with a team a
                    new school model under the "resourcive" principals. He
                    started supporting several NGOs around the world with their
                    projects and visions. In 2010, he started “The ART of Life”
                    movement as a Swiss association to connect meaningful life
                    projects, especially for young adults around the world. By
                    2012, he became a certified Dragon Dreaming Trainer. He has
                    shared this wisdom with many successful projects and people
                    in Europe and Asia. Marco only "works” for joy and for the
                    passion to support the transition movement into a
                    WIN-WIN-WIN solution for the great turning. His vision is to
                    connect meaningful projects and people around the world,
                    contributing to the bigger transformation on planet Earth.
                  </ReadMoreAndLess>
                </BioInfo>
              </BioNameInfoWrapper>
            </Bio>
            <Bio>
              <BioNameInfoWrapper>
                <BioName>Alexander Crawford</BioName>
                <BioInfo>
                  <ReadMoreAndLess
                    charLimit={150}
                    readMoreText=" Read more"
                    readLessText=" Read less"
                  >
                    Alexander Crawford is an advisor and analyst working as an
                    independent consultant and learning process designer. He
                    works in global projects that explore interdependencies and
                    complexity, in Sweden, Europe and China. For over ten years,
                    he was research director and project director at the
                    Tällberg Foundation, where he was lead program designer and
                    project leader for the yearly Tällberg Forum (2005-2013). He
                    has designed, produced and curated dozens of workshops and
                    conferences, and is also an experienced analyst of economic,
                    social and political trends. Alexander is also involved in
                    several non-profits and start-ups. Alexander is 53 years
                    old, grew up in the USA, France and Switzerland, and has
                    lived in Stockholm for 25 years. He has an economics degree
                    from the London School of Economics, is married with two
                    grown children. He enjoys the mountains (skiing, hiking and
                    climbing), the outdoors, good books and zeitgeist-capturing
                    TV-series and films.
                  </ReadMoreAndLess>
                </BioInfo>
              </BioNameInfoWrapper>
              <BioImgContainer>
                <BioImg fluid={data.alexander.childImageSharp.fluid} />
              </BioImgContainer>
            </Bio>
          </BioSection>
          <BioSectionHeading>STRATEGIC PARTNERS</BioSectionHeading>
          <BioSection>
            <Bio>
              <BioImgContainer>
                <BioImg fluid={data.goran.childImageSharp.fluid} />
              </BioImgContainer>
              <BioNameInfoWrapper>
                <BioName>Göran Gennvi</BioName>
                <BioInfo>
                  <ReadMoreAndLess
                    charLimit={150}
                    readMoreText=" Read more"
                    readLessText=" Read less"
                  >
                    Göran is the Founder and CEO of Nature Academy Learning Lab.
                    In 1997, Göran pioneered transformative development methods
                    in Sweden and Europe, collaborating closely with faith
                    keeper Oren Lyons and later on with John P Milton. He has
                    conducted numerous ”Nature Quest” with Swedish and global
                    top leaders Since 2005, Göran has held senior management
                    positions such as senior advisor at Tällberg Foundation and
                    board member of Ekskäret Foundation with the aim to create a
                    conscious society through initiatives that stimulates
                    development at individual and societal level. Göran also
                    held a faculty position at IFL Stockholm Business School
                    (SSE Executive Education) between 1997-2017. Since the early
                    nineties, in a world of constant change, he sees nature as
                    the best teacher. Indeed, Goran is greatly inspired from
                    cultures that are in constant harmony with nature since time
                    memorial. He has developed a long-term and trusting
                    relationship with Sami elders and faith keeper Oren Lyons,
                    and is working in various collaborations with them and other
                    cultures. Goran considers himself as a pathfinder and a
                    Guardian of Life.
                  </ReadMoreAndLess>
                </BioInfo>
              </BioNameInfoWrapper>
            </Bio>
            <Bio>
              <BioNameInfoWrapper>
                <BioName>Caroline Stiernstedt Sahlborn</BioName>
                <BioInfo>
                  <ReadMoreAndLess
                    charLimit={150}
                    readMoreText=" Read more"
                    readLessText=" Read less"
                  >
                    Caroline is a senior consultant working with transformation
                    and leadership development in diverse companies and
                    organisations around the world. She has worked with change
                    for more than 20 years delivering strategic advice and
                    operational execution for leadership programs. These
                    programs consist of design, development, and running of
                    leadership workshops (conferences/ seminars), both in Sweden
                    and internationally. She held a variety of leadership
                    positions, namely at Tällberg Foundation and presently as
                    Chairman of Ekskäret Foundation. In this latest role,
                    Caroline addresses complex challenges to develop new
                    co-creative initiatives that involve strategic partnerships.
                    She draws on her expertise in change management and
                    multi-stakeholder dialogues, gained from her experiences
                    working with and within private, public and non-governmental
                    organisations. In managing change, Caroline aims to
                    contribute better forms of co-creation to catalyse a
                    responsible collective mind shift. As a result, the
                    collective surfaces new solutions to problems that cannot be
                    solved at an individual level. Similar to the Guardian of
                    Life, new insights require the courage and commitment to act
                    responsibly, in parallel to the willingness to explore with
                    curiosity in emergence.
                  </ReadMoreAndLess>
                </BioInfo>
              </BioNameInfoWrapper>
              <BioImgContainer>
                <BioImg fluid={data.caroline.childImageSharp.fluid} />
              </BioImgContainer>
            </Bio>
            <Bio>
              <BioImgContainer>
                <BioImg fluid={data.ilarion.childImageSharp.fluid} />
              </BioImgContainer>
              <BioNameInfoWrapper>
                <BioName>Ilarion Kuuyux Merculieff</BioName>
                <BioInfo>
                  <ReadMoreAndLess
                    charLimit={150}
                    readMoreText=" Read more"
                    readLessText=" Read less"
                  >
                    Founder of Global Center for Indigenous Leadership and
                    Lifeways Ilarion “Larry” is the visionary behind Wisdom
                    Weavers, the project founder, and ongoing key speaker.
                    Raised in a traditional Unangan (Aleut) way and received his
                    Unangan name “Kuuyux” at age 4. The name is given to one
                    person in each lifetime amongst his people. Kuuyux means an
                    arm extending out from the body, a carrier of ancient
                    knowledge into modern times, a messenger. Today he is living
                    the legacy of his name.
                  </ReadMoreAndLess>
                </BioInfo>
              </BioNameInfoWrapper>
            </Bio>
          </BioSection>
          <BioSectionHeading>SERVICE PARTNERS</BioSectionHeading>
          <BioSection>
            <Bio>
              <BioNameInfoWrapper>
                <BioName>Tommy Gärdh</BioName>
                <BioInfo>
                  <ReadMoreAndLess
                    charLimit={150}
                    readMoreText=" Read more"
                    readLessText=" Read less"
                  >
                    We are passionate about producing films that inspire and
                    empower people. We believe in the human potential to create
                    a sustainable world and we love to create content that
                    reflects those values and insights. One example is the
                    documentary film The Urban Green, commissioned by WWF to
                    highlight sustainability efforts being made in cities around
                    the globe. The film was premiered at the United Nations
                    Climate Conference in Paris in December 2015. We truly
                    believe that inspiring storytelling, documentaries and short
                    films enriches the lives of people and have the power to
                    connect, engage, empower and improve our world. We work with
                    both small and large organisations and companies to help
                    tell their story in an inspiring way.
                  </ReadMoreAndLess>
                </BioInfo>
              </BioNameInfoWrapper>
              <BioImgContainer>
                <BioImg fluid={data.tommy.childImageSharp.fluid} />
              </BioImgContainer>
            </Bio>
            <Bio>
              <BioImgContainer>
                <BioImg fluid={data.isak.childImageSharp.fluid} />
              </BioImgContainer>
              <BioNameInfoWrapper>
                <BioName>Isak Joneborg</BioName>
                <BioInfo>
                  <ReadMoreAndLess
                    charLimit={150}
                    readMoreText=" Read more"
                    readLessText=" Read less"
                  >
                    Isak is a psychology student with a passion for nature,
                    music and a rising interest for spirituality. He is
                    currently based in Lund, Sweden, where he also designs
                    websites.
                  </ReadMoreAndLess>
                </BioInfo>
              </BioNameInfoWrapper>
            </Bio>
          </BioSection>
          <BioSectionHeading>COLLABORATORS</BioSectionHeading>
          <BioSection>
            <Bio>
              <BioNameInfoWrapper>
                <BioName>Leo Yankton</BioName>
                <BioInfo>
                  <ReadMoreAndLess
                    charLimit={150}
                    readMoreText=" Read more"
                    readLessText=" Read less"
                  >
                    Leo, also known as Totem, belongs to the Oceti Sakowin
                    (Seven Council Fires) Confederation of Lakota, Dakota, and
                    Nakota Nations. His specific ancestral affiliation is with
                    the Oglala Lakota from Pine Ridge, South Dakota. With a
                    wealth of experiences both on and off the Pine Ridge
                    Reservation, Leo is a community leader, an international
                    speaker, a social, environmental and economic Native
                    Activist. Leo wants to share his culture with people that
                    are interested in learning about the Lakota way of life. He
                    started a website called Nota Medicine Man as an educational
                    project to raise awareness of Indigenous culture.
                  </ReadMoreAndLess>
                </BioInfo>
              </BioNameInfoWrapper>
              <BioImgContainer>
                <BioImg fluid={data.leo.childImageSharp.fluid} />
              </BioImgContainer>
            </Bio>
            <Bio>
              <BioImgContainer>
                <BioImg fluid={data.merle.childImageSharp.fluid} />
              </BioImgContainer>
              <BioNameInfoWrapper>
                <BioName>Merle Lefkoff</BioName>
                <BioInfo>
                  <ReadMoreAndLess
                    charLimit={150}
                    readMoreText=" Read more"
                    readLessText=" Read less"
                  >
                    Merle is Founder and Executive Director of the Center for
                    Emergent Diplomacy, an action research organization based in
                    Santa Fe, New Mexico, U.S. The organization has decades of
                    work in diplomatic negotiations in conflict zones around the
                    world. Presently, the Center applies the principles of
                    complex systems dynamics to scale up change efforts
                    addressing the chaos surrounding “forever wars” and climate
                    catastrophe. Our work includes teaching creative leadership
                    as an emergent property of unprecedented change, requiring a
                    consciousness that we are all Guardians of Life at a moment
                    in time that needs a new story about the resilient world to
                    come.
                  </ReadMoreAndLess>
                </BioInfo>
              </BioNameInfoWrapper>
            </Bio>
            <Bio>
              <BioNameInfoWrapper>
                <BioName>Maria Osterberg</BioName>
                <BioInfo>
                  <ReadMoreAndLess
                    charLimit={150}
                    readMoreText=" Read more"
                    readLessText=" Read less"
                  >
                    Maria has been working professionally with horses, mainly
                    thoroughbreds since 16 years of age as a work rider, jockey
                    and assistant trainer and lived 24/7 with those horses. She
                    still loves thoroughbreds but the industry killed her
                    spirit. Maria quit racing after over 20 years in the
                    business and started travelling with a native horse trainer,
                    taught by his elders in Lame Deer Montana in the Cheyenne
                    reservation. He introduced her to the native culture, which
                    changed her life forever. Maria went to Standing Rock to
                    protest the Dakota Access Pipeline and later volunteered at
                    the Buffalo Field Campaign in Yellow Stone where the last
                    remaining wild bison herds are being watched over by a
                    devoted group of people. She believe horses are healing
                    people and through that we can save horses from slaughter
                    and start Equine Therapy Programs in the native reservations
                    in North America. The competition world needs a higher
                    awareness and an ”indigenous” understanding of the horse as
                    a spiritual and biological creature.
                  </ReadMoreAndLess>
                </BioInfo>
              </BioNameInfoWrapper>
              <BioImgContainer>
                <BioImg fluid={data.maria.childImageSharp.fluid} />
              </BioImgContainer>
            </Bio>
            <Bio>
              <BioImgContainer>
                <BioImg fluid={data.jan.childImageSharp.fluid} />
              </BioImgContainer>
              <BioNameInfoWrapper>
                <BioName>Jan Friman</BioName>
                <BioInfo>
                  <ReadMoreAndLess
                    charLimit={150}
                    readMoreText=" Read more"
                    readLessText=" Read less"
                  >
                    Jan has been an entrepreneur at heart since starting his
                    first company at 18, setting up a progressive art gallery in
                    his apartment in Stockholm, Sweden. His previous background
                    as a project manager in the global advertising industry
                    proved valuable when in the beginning of 2013 immersing
                    himself in the fast paced startup scene, co-founding
                    Freelway, one of Sweden’s first true sharing economy
                    enterprises. As a management consultant and aspiring coach
                    with experience working both with global brands, small
                    businesses and government organizations, Jan early began to
                    develop a deep interest and passion for the intersection
                    between online communication, innovation and mutual
                    learning. During the last few years, focusing on his
                    consultancy business, a realization emerged, exposing that
                    genuine organizational learning and growth need to be
                    facilitated through a well linked and synchronized network
                    of people who genuinely care both for themselves, everyone
                    else as well as the planet at large. All in well balanced
                    measures through pure intention. To genuinely care for other
                    beings and the environment as a coherent ecosystem are
                    fundamental and at the core of being a Guardian of Life.
                  </ReadMoreAndLess>
                </BioInfo>
              </BioNameInfoWrapper>
            </Bio>
            <Bio>
              <BioNameInfoWrapper>
                <BioName>Jenny Andersson</BioName>
                <BioInfo>
                  <ReadMoreAndLess
                    charLimit={150}
                    readMoreText=" Read more"
                    readLessText=" Read less"
                  >
                    Jenny is a creative strategist working with organisations
                    who want to be part of a regenerative future for people and
                    planet, and a trusted advisor for their CEOs and leadership
                    teams. She uses collective learning processes and change
                    programmes to help organisations develop their
                    sustainability strategies and strategic narratives, and
                    activate them through co-creativel processes. She calls on
                    30 years of experience advising global purpose-led brands
                    and scale-ups to deliver world-class facilitated
                    interventions which deliver results. She is a trained
                    facilitator in Theory U, Art of Hosting, and more recently
                    is training in biomimicry for social innovation. She designs
                    learning experiences for leadership teams who want to
                    develop creative thinking, and disruptive and regenerative
                    innovation ideas. She curates experiences where people are
                    empowered to ask challenging questions and create
                    transformational solutions. She is founder of We Activate
                    The Future, The Bioregional Reslience Trust and Senior
                    Adviser to She Leads Change.
                  </ReadMoreAndLess>
                </BioInfo>
              </BioNameInfoWrapper>
              <BioImgContainer>
                <BioImg fluid={data.jenny.childImageSharp.fluid} />
              </BioImgContainer>
            </Bio>
            <Bio>
              <BioImgContainer>
                <BioImg fluid={data.joe.childImageSharp.fluid} />
              </BioImgContainer>
              <BioNameInfoWrapper>
                <BioName>Joe Ross</BioName>
                <BioInfo>
                  <ReadMoreAndLess
                    charLimit={150}
                    readMoreText=" Read more"
                    readLessText=" Read less"
                  >
                    An Author, Poet, and Social Strategy Designer, Joe Ross
                    works in developing new thinking around economic and social
                    issues, often including the Arts as a transformational
                    vehicle for positive change. He has served as Chief of
                    Policy and Senior Advisor to prominent institutions,
                    foundations, and governments including the City of San
                    Diego, California, PlaNet Finance in Paris, and the Tallberg
                    Foundation in Stockholm.
                  </ReadMoreAndLess>
                </BioInfo>
              </BioNameInfoWrapper>
            </Bio>
            <Bio>
              <BioNameInfoWrapper>
                <BioName>Talya Weinberg</BioName>
                <BioInfo>
                  <ReadMoreAndLess
                    charLimit={150}
                    readMoreText=" Read more"
                    readLessText=" Read less"
                  >
                    Talya Weinberg is a social and humanitarian clown, actress,
                    artivist, designer, and cultural and social entrepreneur.
                    She is a facilitator of the Dragon Dreaming process and a
                    social innovation expert. Talya is from Colombia, where she
                    currently lives, having also lived in Israel for 16 years.
                    Talya and her partner Dror Noy founded the ethical fashion
                    brand Yumajai, which supports indigenous artists and
                    communities. They facilitate change processes in Latin
                    America and the world through their international
                    consultancy InsideHub. She founded the nomadic theater group
                    ‘Kismaya’, which uses education, play, ecology, and art to
                    deliver messages of inspiration, and hope to children around
                    the world. Talya has worked as a clown in a variety of
                    contexts--in hospitals around the world, in the
                    Palestinian-Israeli conflict, with refugees in Europe, in
                    peace processes in Colombia, with vulnerable communities in
                    Peru along with mentor Patch Adams, and in communities
                    facing environmental crises such as plastic in the ocean off
                    the shores of Colombia. Over the past six years, she has
                    developed the Heart Spaces model, a method for designing
                    entry strategies for communities working with social
                    clowning and social technologies.
                  </ReadMoreAndLess>
                </BioInfo>
              </BioNameInfoWrapper>
              <BioImgContainer>
                <BioImg fluid={data.talya.childImageSharp.fluid} />
              </BioImgContainer>
            </Bio>
          </BioSection>
          <BioSectionHeading>ADVISORS</BioSectionHeading>
          <BioSection>
            <Bio>
              <BioImgContainer>
                <BioImg fluid={data.robert.childImageSharp.fluid} />
              </BioImgContainer>
              <BioNameInfoWrapper>
                <BioName>Robert Krook</BioName>
                <BioInfo>
                  <ReadMoreAndLess
                    charLimit={150}
                    readMoreText=" Read more"
                    readLessText=" Read less"
                  >
                    Beyond his early service as an elite military officer he has
                    worked for over 15 years in a multinational environment
                    across thirty-seven geographies in Europe and the USA. His
                    experience and successful track record in the areas of
                    strategic Human Resources and General Management in a highly
                    brand oriented environment allows him to deliver a unique
                    perspective in the strategic planning process as well as in
                    tactical implementation processes. Today Robert is a
                    recognised expert on leadership and strategy. His focus is
                    on inspiring and developing executive teams, mentoring
                    senior business leaders, facilitating strategic workshops
                    and supporting execution and organisational change. His
                    distinctive personal style and use of innovative tools and
                    solutions has helped numerous teams and individuals make a
                    leap in performance. “I believe that a person’s performance
                    is determined by their perception of self-worth, how
                    stimulated they are intellectually and how good their
                    physical health is. This is true for myself too; when I’m in
                    that “zone” I succeed with everything I do. So I have
                    brought my passion and values into the work I do."
                  </ReadMoreAndLess>
                </BioInfo>
              </BioNameInfoWrapper>
            </Bio>
          </BioSection>
          <BioSectionHeading>DONORS</BioSectionHeading>
          <BioSection>
            <BioInfo>Tomas Björkman - Ekskäret Foundation</BioInfo>
            <BioInfo>Ulf Stenerhag - Wayoutintl</BioInfo>
            <EmailButtonWrapper>
              <EmailButton href="mailto:hello@guardianoflife.org">
                hello@guardianoflife.org
              </EmailButton>
            </EmailButtonWrapper>
          </BioSection>
        </BioSectionWrapper>
      </WhoSectionWrapper>
    </>
  )
}

export default Who
