import React from "react"
import { graphql, Link, useStaticQuery } from "gatsby"
import PropTypes from "prop-types"
import Img from "gatsby-image"
import styled from "styled-components"

import {
  FullWidthImageWrapper,
  SectionWrapper,
  ColumnWrapper,
  Column,
  ColumnText,
  ColumnHeading,
  CitationWrapper,
  CitationText,
  CitationHeading,
  PageHeadingWrapper,
  PageHeading,
  FadedShapeContainerBottom,
  FadedShapeBottom,
  FadedShapeContainerTop,
  FadedShapeTop,
  ProgressBarContainer,
  ProgressBar,
  ScrollDownContainer,
  ScrollDownText,
  ScrollDownIcon,
} from "../pagecomponents/page-layout"

import Footer from "../footer"
import scrollDown from "../scroller"
import Scroll from "react-scroll"

const WhyCitationText = styled(CitationText)``

const Why = () => {
  const Element = Scroll.Element

  const data = useStaticQuery(graphql`
    query {
      cover: file(relativePath: { eq: "glc-full-width-larger-height.png" }) {
        childImageSharp {
          fluid(maxWidth: 4096, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      scrolldown: file(relativePath: { eq: "scrolldown.png" }) {
        childImageSharp {
          fluid(maxWidth: 4096, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  return (
    <>
      <ProgressBarContainer>
        <ProgressBar />
      </ProgressBarContainer>
      <SectionWrapper>
        <PageHeadingWrapper>
          <PageHeading>WHY</PageHeading>
        </PageHeadingWrapper>

        <CitationWrapper>
          <WhyCitationText>
            What and how is it to embody the Guardian of Life? What would it
            take for society to live in balance and harmony co-existing with all
            life forms? How would this wisdom be embraced at cellular level
            rather than an intellectual exercise?
          </WhyCitationText>
        </CitationWrapper>
        <ScrollDownContainer onClick={() => scrollDown("second-section")}>
          <ScrollDownText>READ MORE</ScrollDownText>
          <ScrollDownIcon fluid={data.scrolldown.childImageSharp.fluid} />
        </ScrollDownContainer>
      </SectionWrapper>
      <FullWidthImageWrapper>
        <Img fluid={data.cover.childImageSharp.fluid} />
      </FullWidthImageWrapper>
      <Element name="second-section"></Element>
      <SectionWrapper>
        <ColumnWrapper>
          <Column>
            <ColumnText>
              We are looking to tackle the deeper root cause - the mindset and
              culture that create and develop any manmade system. Currently, we
              observe that this mindset and culture in most societies is not
              aligned and connected with how the living system of all living
              forms functions and its lifecycles. Everything is independent and
              interdependent in a living organism, whether it’s physically
              visible to us or not.
            </ColumnText>
            <ColumnText>
              Fundamentally there is an underlying belief system that all humans
              are not part of this abundant and interconnected living system.
              That we are in control of living systems and can exploit and
              extract as we wish.
            </ColumnText>
            <ColumnText>
              This separation belief do not resemble the living organism and
              system we all belong to. Consequently, most manmade systems,
              processes and governances result in unintended consequences such
              as the extractions and exploitations of natural resources and
              people.
            </ColumnText>
            <ColumnText>
              Our approach is to first find positive deviations in the human
              race and living organism where communities and societies have
              lived the timeless wisdom at cellular level (embodied way) for
              centuries, learning and experimenting in how to live in harmony in
              a regenerative way and purpose as an whole organism with all life
              forms and its lifecycles, combining timeless wisdom and modern
              wisdom from all sources. Why start from scratch when the wisdom is
              all around us including in nature?
            </ColumnText>
          </Column>
          <Column>
            <ColumnText>
              Why start from scratch when the wisdom is all around us including
              in nature?
            </ColumnText>
            <ColumnText>
              Acting as one interconnected/interdependent organism, can we seed
              and support an awakened culture actively chosing abundance and
              generosity?
            </ColumnText>
            <ColumnHeading>LIVING VISION</ColumnHeading>
            <ColumnText>
              What and how is it to embody the Guardian of Life? A caretaker of
              all living forms with wholeness (interdependence) mindset, in
              alignment to Laws of Nature and natural lifecycle of living.
            </ColumnText>
          </Column>
          <FadedShapeContainerBottom>
            <FadedShapeBottom />
          </FadedShapeContainerBottom>
        </ColumnWrapper>
      </SectionWrapper>
    </>
  )
}

export default Why
